import { Methods } from 'widgets/methods/ui';

const MethodsPage = () => {
  return (
    <>
      <Methods />
    </>
  );
};

export default MethodsPage;
