import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { Noop } from 'react-hook-form';
import { TodayIcon } from 'shared/assets/icons';
import { DateRange } from 'shared/services/types/common.types';
import { ClearFormItem } from 'shared/ui/clear-form-button';

interface IProps {
  value?: DateRange;
  onChange: React.Dispatch<React.SetStateAction<DateRange>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onBlur?: Noop;
}

export const DateRangeSlice: FC<IProps> = ({ value, onChange, isOpen, setIsOpen, setIsDropdownOpen, onBlur }) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [reRender, setReRender] = useState(false);
  const onToggleKey = () => {
    setReRender((prev) => !prev);
  };

  return (
    <>
      <DateRangePicker
        onChange={onChange}
        value={value}
        dayPlaceholder='dd'
        monthPlaceholder='mm'
        yearPlaceholder='yyyy'
        format='MM/dd/yyyy'
        calendarIcon={<TodayIcon />}
        showLeadingZeros
        maxDate={new Date()}
        onBlur={onBlur}
        isOpen={isOpen}
        clearIcon={
          <ClearFormItem
            rightPercent={10}
            onClick={() => {
              setStartDate(new Date());
              onToggleKey();
            }}
          />
        }
        shouldCloseCalendar={({ reason }) => {
          return reason === 'buttonClick';
        }}
        onCalendarOpen={() => {
          setIsOpen(true);
          return;
        }}
        onClick={(e) => {
          return value === null ? setStartDate(undefined) : null;
        }}
        calendarProps={{
          locale: 'en-US',
          formatShortWeekday: (locale, date) => {
            return format(date, 'EEEEE');
          },
          next2Label: null,
          prev2Label: null,
          allowPartialRange: true,
          activeStartDate: startDate,
          onViewChange({ action, activeStartDate, value, view }) {
            setIsDropdownOpen(view !== 'month');
          },
          key: reRender.toString(),
        }}
      />
    </>
  );
};
