import axios, { AxiosError } from 'axios'

import { API_URL } from 'shared/api/config/api.config'
import { removeTokensStorage } from 'shared/services/auth/auth.helper'
import { AuthService } from 'shared/services/auth/auth.service'

export const axiosClassic = axios.create({
  baseURL: API_URL,
});


const interceptorsResponseError = async (error: AxiosError<any>) => {
  const originalRequest = { ...error.config, _isRetry: false };

  if ([401].includes(error.response!.status) && error.config && !originalRequest._isRetry) {
    originalRequest._isRetry = true;

    try {
      if(error.response?.config.url === '/user/refresh') {
        throw error;
      }

      await AuthService.refreshToken();

      return axiosClassic.request(originalRequest);
    } catch (error) {
      removeTokensStorage();
      return error;
    }
  }

  throw error;
};

axiosClassic.interceptors.response.use(async (config) => {
  return config;
}, interceptorsResponseError);