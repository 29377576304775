import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TMethods } from 'shared/services/types/methods.types';
import { MethodsService } from 'shared/services/methods.service';

export const useCreateMethod = () => {
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: (data: TMethods.CreateMethod) => MethodsService.createMethod(data),
    mutationKey: ['create method'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get methods'] });
    },
  });

  return { create };
};
