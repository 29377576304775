import { Box, Button, FormControl, Grid, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Control, FieldValues, Noop, RegisterOptions } from 'react-hook-form';
import CircularProgressCenter from '../CircularProgressCenter';
import { ClearFormItem } from '../clear-form-button';

interface IProps extends PropsWithChildren {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  labelInner?: string;
  htmlId: string;
  disabled?: boolean;
  onBlur?: Noop;
  isLoading?: boolean;
  control?: Control<FieldValues>;
  rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  name: string;
  isFooter?: boolean;
}

export const FilterItem: FC<IProps> = ({
  value,
  children,
  setValue,
  labelInner,
  placeholder,
  htmlId,
  onBlur,
  disabled,
  isLoading,
  control,
  rules,
  name,
  isFooter,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <FormControl fullWidth sx={{ minWidth: '170px' }}>
      <InputLabel shrink sx={{ backgroundColor: '#fff' }} htmlFor='htmlId'>
        {labelInner}
      </InputLabel>
      <Select
        labelId={htmlId}
        key={value}
        name={name}
        id={htmlId}
        onBlur={onBlur}
        disabled={disabled || isLoading}
        value={value}
        label={labelInner}
        style={!value ? { color: '#90939B' } : {}}
        displayEmpty
        inputProps={(control = control)}
        renderValue={(selected) => {
          if (selected === '' || selected === undefined) {
            return <>{placeholder}</>;
          } else {
            if (children instanceof Array) {
              return children?.map((item) => (item.props.value === value ? item.props.children : ''));
            }
          }
        }}
        onChange={handleChange}
      >
        {children}
        {isFooter && (
          <Grid container sx={{ padding: '6px 10px 0px', gap: '10px' }} justifyContent='space-between'>
            <Grid
              item
              sx={{
                color: 'rgba(29, 27, 32, 0.50)',
                fontDamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0.04px',
              }}
            >
              <Button sx={{ padding: 0 }}>Cancel</Button>
            </Grid>
            <Grid
              item
              sx={{
                color: 'rgba(24, 90, 194, 0.20)',
                fontDamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0.04px',
              }}
            >
              <Button sx={{ padding: 0 }}>Ok</Button>
            </Grid>
          </Grid>
        )}
      </Select>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '45%',
            right: 30,
            transform: 'translate(0%, -50%)',
            width: 27,
            height: 27,
            cursor: 'pointer',
            minWidth: 27,
            p: 0,
          }}
        >
          <CircularProgressCenter isSelect />
        </Box>
      )}

      {value && !isLoading && !disabled && (
        <ClearFormItem
          rightPercent={5}
          onClick={() => {
            setValue('');
          }}
        />
      )}
    </FormControl>
  );
};
