import { toFormData } from 'axios';
import { axiosClassic } from 'shared/api';
import { getUserUrl } from 'shared/api/config/api.config';
import { TAuth } from '../types/auth.types';
import { TTokens } from '../types/common.types';
import { removeTokensStorage, saveTokensStorage } from './auth.helper';

export const AuthService = {
  async login(data: TAuth.Login) {
    const response = await axiosClassic.post<TAuth.LoginSuccess>(getUserUrl('/login'), toFormData(data));

    if (response.data.token.access_token ?? !response.data.token.refresh_token) {
      saveTokensStorage({
        access_token: response.data.token.access_token,
      });
    }

    if (data.checkbox_remember) {
      saveTokensStorage({
        access_token: response.data.token.access_token,
        refresh_token: response.data.token.refresh_token,
      });
    }

    return response;
  },
  async refreshToken() {
    const refresh = localStorage.getItem('refresh');
    if (!refresh) {
      this.logout();
    }
    try {
      const response = await axiosClassic.get<TTokens>(getUserUrl(`/refresh`), {
        headers: {
          Authorization: `Bearer ${refresh}`,
        },
      });
      if (response.data.access_token) {
        saveTokensStorage(response.data);
      }
      return response;
    } catch (error: any) {
      if ([401].includes(error.response.status)) {
        this.logout();
      }
    }
  },

  async logout() {
    removeTokensStorage();
  },
};
