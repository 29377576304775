import { Box, Button, Paper, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  handleConfirmClose: () => void;
  handleCancelClose: () => void;
}

const CloseModal: FC<IProps> = ({ handleCancelClose, handleConfirmClose }) => {
  return (
    <Paper
      sx={{
        width: '631px',
        borderRadius: 4,
        overflow: 'visible',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '38px 24px 30px 24px',
        textAlign: 'center',
        gap: '30px',
      }}
    >
      <Typography variant='h5'>
        Are you sure you want to close this window?
        <br />
        All unsaved data will be deleted
      </Typography>
      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
        <Button onClick={handleCancelClose} variant='contained' sx={{ width: '172px' }}>
          Cancel
        </Button>
        <Button onClick={handleConfirmClose} variant='outlined' sx={{ width: '172px' }}>
          Ok
        </Button>
      </Box>
    </Paper>
  );
};

export default CloseModal;
