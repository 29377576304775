import { Box, Button, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CURRENCY } from 'shared/constants/common';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { IModalProps, ModalTemplate } from 'shared/ui/modal-template';
import { Select } from 'shared/ui/select';
import { Tabs } from 'shared/ui/tabs';
import { hasFormValuesChanged } from 'shared/utils';
import { IStepProps } from '..';

interface IProps extends IModalProps, IStepProps {
  onRegexEditor?: () => void;
  onSubmit: (data: any) => void;
  formOne: UseFormReturn<FieldValues, any, undefined>;
  formTwo: UseFormReturn<FieldValues, any, undefined>;
  activeTab: number;
}

export const MainInfoModal: FC<IProps> = ({
  close,
  isOpen,
  onRegexEditor,
  onSubmit,
  formOne,
  formTwo,
  onNext,
  activeTab,
}) => {
  const formErrors = formOne.formState.errors;
  const formErrorsTwo = formTwo.formState.errors;
  const isConfirmationNeeded = hasFormValuesChanged(formOne.watch());
  const [activeId, setActiveId] = useState(activeTab || 0);

  useEffect(() => {
    if (activeTab !== undefined) {
      setActiveId(activeTab);
    }
  }, [activeTab]);

  const onClickActive = (id: number) => {
    setActiveId(id);
  };

  const onReset = () => {
    formOne.reset();
    formTwo.reset();
  };

  return (
    <div>
      <ModalTemplate
        isConfirmationNeeded={isConfirmationNeeded}
        close={close}
        isOpen={isOpen}
        onBack={activeTab === 1 ? () => onClickActive(0) : undefined}
        titleText={'Add method'}
        hideBackdrop={false}
        reset={onReset}
      >
        <Box p={'0 15px'}>
          <Tabs
            activeTab={activeId}
            onClick={onClickActive}
            disabledHeader={activeTab !== 1}
            headerTabs={[
              { id: 0, title: 'Main' },
              { id: 1, title: 'Settings' },
            ]}
          >
            <FormWrapper
              onSubmit={() => {
                onNext?.();
                onClickActive(1);
              }}
              methods={formOne}
              padding=' 0'
            >
              <Box p={'30px 0'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
                    <Grid item>
                      <Input
                        name='name'
                        label='Method name *'
                        placeholder='Enter method name'
                        rules={{ required: 'Please, enter method name' }}
                        control={formOne.control}
                        error={formErrors.name?.message?.toString()}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        name='min_value'
                        label='Minimal sum *'
                        type='number'
                        placeholder='Enter minimal sum'
                        rules={{ required: 'Please, enter minimal sum' }}
                        control={formOne.control}
                        error={formErrors.min_value?.message?.toString()}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        name='decline_time'
                        label='Decline time *'
                        type='number'
                        placeholder='Enter decline time'
                        rules={{
                          required: 'Please, enter decline time',
                        }}
                        control={formOne.control}
                        error={formErrors.decline_time?.message?.toString()}
                      />
                    </Grid>
                    <Grid item sx={{ maxHeight: '350px' }}>
                      <Select
                        name='currency'
                        label='Currency *'
                        control={formOne.control}
                        options={CURRENCY}
                        placeholder='Choose currency for payment method'
                        rules={{
                          required: 'Please, choose currency',
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Input
                        name='ussd_command'
                        label='USSD command *'
                        placeholder='Enter USSD command for payment method'
                        rules={{ required: 'Please, enter USSD command' }}
                        control={formOne.control}
                        error={formErrors.ussd_command?.message?.toString()}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display={'flex'} gap='10px'>
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => close()}
                    sx={{ borderRadius: 50, textTransform: 'none', height: 40, marginTop: 15, marginBottom: 3 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    sx={{ borderRadius: 50, textTransform: 'none', height: 40, marginTop: 15, marginBottom: 3 }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </FormWrapper>
            <FormWrapper onSubmit={onSubmit} methods={formTwo} padding=' 0'>
              <Box p={'30px 0'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
                    <Grid item>
                      <Select
                        control={formTwo.control}
                        error={formTwo.formState.errors}
                        required
                        name='compare_field'
                        placeholder='Text'
                        label='Сomparisons*'
                        rules={{
                          required: 'Required field',
                        }}
                        options={[
                          { value: 'amount', label: 'Amount' },
                          { value: 'remarks', label: 'Remarks' },
                          { value: 'remarks_amount', label: 'Amount with remarks' },
                          { value: 'amount_balance', label: 'Amount with balance' },
                        ]}
                      />
                    </Grid>

                    <Grid item>
                      <Input
                        name='regex'
                        label='Regular expressions* *'
                        placeholder='Texts'
                        rules={{ required: 'Please, enter currency' }}
                        control={formTwo.control}
                        error={formErrorsTwo.regex?.message?.toString()}
                        isEdit
                        onClickIcon={onRegexEditor}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display={'flex'} gap='10px'>
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => onClickActive(0)}
                    sx={{ borderRadius: 50, textTransform: 'none', height: 40, marginTop: 15, marginBottom: 3 }}
                  >
                    Back
                  </Button>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    sx={{ borderRadius: 50, textTransform: 'none', height: 40, marginTop: 15, marginBottom: 3 }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </FormWrapper>
          </Tabs>
        </Box>
      </ModalTemplate>
    </div>
  );
};
