import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { TPagination } from 'shared/services/types/common.types';
import { UsersService } from 'shared/services/users.service';

export const useGetUsers = (data: TPagination) => {
  const {
    isLoading: isUsersLoading,
    data: usersData,
    isError: isUsersError,
    isFetching: isUsersFetching,
  } = useQuery({
    queryFn: () => UsersService.getUsers(data),
    queryKey: ['get users', data],
    retry: 0,
    select: ({ data }) => data,
    placeholderData: keepPreviousData,

  });

  return { isUsersLoading, usersData, isUsersError, isUsersFetching };
};
