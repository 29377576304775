import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import type { ClientRectObject } from '@popperjs/core';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { AddApplication } from 'shared/assets/icons';

interface IProps extends PropsWithChildren {
  columns: GridColDef[];
  thicon: ReactNode;
}

export const TableContentWrapper: FC<IProps> = ({ columns, thicon, children }) => {
  return (
    <Table
      stickyHeader
      sx={{
        minWidth: 0,
        width: '50%',
        borderBottom: 'none',
        position: 'relative',
        '::-webkit-scrollbar': {
          display: 'none',
          width: '0',
          background: 'transparent',
        },
        '.MuiCircularProgress-root': {
          width: '52px',
          height: '52px',
        },
      }}
    >
      <TableHead>
        <TableRow sx={{ borderRadius: '16px 16px' }}>
          {columns.map((column: any, id: number) => (
            <TableCell
              key={id}
              align={column.headerName === 'AddApplication' ? 'right' : 'left'}
              sx={{
                whiteSpace: 'nowrap',
                position: 'relative',
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: '500',
                width: column.width,
                paddingRight: column.headerName === 'AddApplication' ? '15px' : 'none',
              }}
            >
              {column.headerName === 'AddApplication' ? (
                <>
                  {/* // TODO убрана кнопка add 
                // <Tooltip
                //   title='Add'
                //   arrow
                //   disableInteractive
                //   slotProps={{
                //     popper: {
                //       modifiers: [
                //         {
                //           name: 'offset',
                //           options: {
                //             offset: ({ popper }: { popper: ClientRectObject }) => {
                //               return [0, 0];
                //             },
                //           },
                //         },
                //       ],
                //     },
                //   }}
                // >
                //   <AddApplication style={{ verticalAlign: 'middle', cursor: 'pointer' }} />
                // </Tooltip></TableCell> */}
                </>
              ) : column.headerName === '' && id === 0 ? (
                thicon
              ) : (
                column.headerName
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          minHeight: 1,
          mt: '54px',
          '.MuiTableCell-root': {
            fontSize: 16,
            lineHeight: '24px',
          },
          '.status__wrapper': {
            margin: '0',
          },
        }}
      >
        {children}
      </TableBody>
    </Table>
  );
};
