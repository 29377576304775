import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Modal, Paper, SxProps, Theme, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { CloseIcon } from 'shared/assets/icons';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import CloseModal from '../close-modal-window';
import styles from './modal-template.module.sass';

export interface IModalProps {
  isOpen: boolean;
  close: (isReset?: boolean) => void;
}

interface IProps extends PropsWithChildren, IModalProps {
  titleText: string;
  subtitleText?: string;
  subButton?: boolean;
  hideBackdrop?: boolean;
  hideCloseIcon?: boolean;
  isDark?: boolean;
  reset?: () => void;
  maxWidth?: number;
  isConfirmationNeeded?: boolean;
  onBack?: () => void;
  sx?: SxProps<Theme>;
}

export const ModalTemplate: FC<IProps> = ({
  isOpen,
  titleText,
  subtitleText,
  children,
  hideBackdrop,
  close,
  hideCloseIcon,
  isDark,
  maxWidth,
  onBack,
  isConfirmationNeeded = false,
  sx,
}) => {
  const { ref, showConfirm, setShowConfirm, handleConfirm } = useOutsideClick(
    isConfirmationNeeded,
    false,
    () => {
      close(true);
    },
    'MuiModal-backdrop'
  );

  const handleConfirmClose = () => {
    handleConfirm(true);
  };

  const handleCancelClose = () => {
    handleConfirm(false);
  };

  return (
    <Modal
      open={isOpen}
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      hideBackdrop={hideBackdrop}
      className={styles.container && isDark ? styles.isDark : ''}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      style={{ overflow: 'auto', display: 'flex', justifyContent: 'center' }}
    >
      {showConfirm ? (
        <CloseModal handleConfirmClose={handleConfirmClose} handleCancelClose={handleCancelClose} />
      ) : (
        <Paper
          sx={{
            borderRadius: 4,
            width: maxWidth ? '100%' : 413,
            position: 'relative',
            maxWidth,
            overflow: 'visible',
            margin: 'auto',
          }}
        >
          <Box ref={ref}>
            {onBack && (
              <Box
                sx={{
                  display: (hideCloseIcon && 'none') || 'block',
                  position: 'absolute',
                  top: '16px',
                  left: '17px',
                  width: 25,
                  height: 25,
                  color: '#185AC2',
                  cursor: 'pointer',
                }}
                onClick={onBack}
              >
                <ArrowBackIcon />
              </Box>
            )}

            <Box
              sx={{
                display: (hideCloseIcon && 'none') || 'block',
                position: 'absolute',
                top: '16px',
                right: '17px',
                width: 25,
                height: 25,
                color: '#90939B',
                cursor: 'pointer',
              }}
              onClick={() => {
                isConfirmationNeeded ? setShowConfirm(true) : close();
              }}
            >
              <CloseIcon />
            </Box>
            <Box sx={{ paddingTop: 15, borderRadius: 4 }}>
              <Typography component='h1' className={styles.title} sx={{ textAlign: 'center' }}>
                {titleText}
              </Typography>
              {subtitleText && (
                <Typography
                  sx={{ mt: 4, textAlign: 'center', color: 'text.secondary', fontSize: '14px', lineHeight: '20px' }}
                >
                  {subtitleText}
                </Typography>
              )}
            </Box>
            {children}
          </Box>
        </Paper>
      )}
    </Modal>
  );
};
