import { Box, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Control, Controller, FieldErrors, FieldValues, RegisterOptions } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';

interface IProps {
  type?: string;
  name: string;
  control: Control<FieldValues>;
  label?: string;
  placeholder?: string;
  error?: FieldErrors<FieldValues> | string;
  helperText?: string;
  required?: boolean;
  rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  isEdit?: boolean;
  onClickIcon?: () => void;
}
export const Input: FC<IProps> = ({
  control,
  name,
  rules,
  error,
  label,
  placeholder,
  required,
  type,
  helperText,
  isEdit,
  onClickIcon,
}) => {
  const errorMessage = typeof error === 'string' ? error : (error?.[name]?.message as string);
  return (
    <Box style={{ position: 'relative' }}>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            margin='normal'
            fullWidth
            required={required}
            id={name}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            type={type}
            sx={{
              m: 0,
              p: 0,
              fieldset: {
                border: errorMessage && '1px solid #b20000 ',
              },
            }}
            InputProps={{
              endAdornment: isEdit && (
                <Box sx={{ cursor: onClickIcon ? 'pointer' : 'default' }} onClick={onClickIcon}>
                  <EditIcon />
                </Box>
              ),
            }}
          />
        )}
      />
      <Box
        style={{
          marginLeft: '16px',
          fontSize: helperText ? 13 : 12,
          color: helperText ? '#49454F' : '#b20000',
          textAlign: 'start',
          fontWeight: helperText ? 600 : 'normal',
        }}
      >
        {(helperText && <span>{helperText}</span>) || errorMessage}
      </Box>
    </Box>
  );
};
