import { Box, Button, Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { EMAIL_REGEX, ROLE_USER } from 'shared/constants';
import { DateRange } from 'shared/services/types/common.types';
import { FilterItemDate } from 'shared/ui/filter-item-date';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { Select } from 'shared/ui/select';
import { IStep } from '.';

interface IProps extends IStep {
  userData: any;
  onClose: () => void;
  setBirthday: React.Dispatch<React.SetStateAction<string | undefined>>;
  birthday?: string;
  required?: string;
}

export const UserForm: FC<IProps> = ({
  methods,
  onSubmit,
  setStep,
  userData,
  onClose,
  birthday,
  setBirthday,
  methodsOptions,
  required,
}) => {
  const [bday, setBday] = useState<DateRange>(null);

  useEffect(() => {
    typeof birthday === 'string' && setBday(new Date(birthday));
  }, [birthday]);

  return (
    <FormWrapper onSubmit={onSubmit} methods={methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          direction={'column'}
          columnGap={'20px'}
          rowGap={15}
          wrap={'nowrap'}
          gridTemplateColumns={'1fr '}
          display='grid'
          gridTemplateRows='auto auto 1fr'
        >
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              label='Name'
              name='name'
              rules={{ required: required }}
            />
          </Grid>
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              label='Email'
              name='email'
              rules={{
                required: required,
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Enter the correct email',
                },
              }}
            />
          </Grid>
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              label='Password'
              name='password'
              rules={{ required: required, minLength: { message: 'Minimum length - 4', value: 4 } }}
            />
          </Grid>
          <Grid item>
            <Select
              control={methods.control}
              error={methods.formState.errors}
              name='method_id'
              placeholder='Text'
              label='Method'
              rules={{
                required: required,
              }}
              options={methodsOptions}
            />
          </Grid>
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              label='Phone'
              name='phone'
            />
          </Grid>

          <Grid item>
            <Select
              control={methods.control}
              error={methods.formState.errors}
              name='role'
              placeholder='Text'
              label='Role'
              options={ROLE_USER.map((item) => ({ label: item.value, value: item.label }))}
            />
          </Grid>
          <Grid
            item
            sx={{
              '.MuiFormControl-root': {
                width: '100%',
              },
              '.react-date-picker': {
                '&__wrapper': {
                  maxWidth: '100%',
                },
              },
            }}
          >
            <FilterItemDate mono value={bday} setValue={setBday} labelText='Birthday' />
          </Grid>
        </Grid>
      </Box>
      <Box mt={'30px'} display={'flex'} gap={'16px'} alignItems={'center'} justifyContent={'center'}>
        <Button
          variant='contained'
          fullWidth
          onClick={onClose}
          sx={{
            maxWidth: 175,
            m: 0,
            borderRadius: 50,
            textTransform: 'none',
            background: '#E3E3E4',
            color: '#1D1B20',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#e2e2e2',
            },
            height: 40,
          }}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none', maxWidth: 175, height: 40, boxShadow: 'none' }}
        >
          {userData ? <>Save</> : <>Next</>}
        </Button>
      </Box>
    </FormWrapper>
  );
};
