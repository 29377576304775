import { useLayoutEffect, useState } from 'react';
import useAuthStore from './useAuthStore';

const useUser = () => {
  const { user } = useAuthStore();
  const [isAdmin, setIsAdmin] = useState(user?.role === 'Администратор');
  const [name, setName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [role, setRole] = useState(user?.role);

  useLayoutEffect(() => {
    if (user) {
      setName(user.name);
      setCreatedAt(user?.created_at);
      setIsAdmin(user?.role === 'Администратор');
    }
  }, []);

  return { role, createdAt, isAdmin, name, user };
};
export default useUser;
