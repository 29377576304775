import { useMutation } from '@tanstack/react-query';
import { TWithdrawal } from 'shared/services/types/withdrawal.types';
import { WithdrawalService } from 'shared/services/withdrawal.service';

export const useCreateWithdrawal = () => {
  const create = useMutation({
    mutationFn: (data: TWithdrawal.WithdrawalCreate) => WithdrawalService.create(data),
    mutationKey: ['create withdrawal'],
  });

  return { create };
};
