import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TUsers } from 'shared/services/types/users.types';
import { UsersService } from 'shared/services/users.service';

export const useCreateAccount = () => {
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: (data: TUsers.CreateAccount) => UsersService.createAccount(data),
    mutationKey: ['create user'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get account'] });
    },
  });

  return { create };
};
