import { add, format } from 'date-fns';
import { DateRange } from 'shared/services/types/common.types';

export const useGetDate = () => {
  const getDate = (propID: number, filter?: DateRange) => {
    if (Array.isArray(filter)) {
      const result = filter.find((date: any, id: number) => {
        if (id === propID) {
          if (date === null || date === undefined) {
            return null;
          } else {
            return date;
          }
        } else {
          return null;
        }
      });
      if (result instanceof Date) {
        return format(
          add(result, {
            days: propID,
          }),
          'yyyy-MM-dd'
        );
      }
    } else {
      return null;
    }
  };
  return { getDate };
};
