export const getTextFromBlob = async (blob: Blob) => {
  try {
    const text = await blob.text();
    if (JSON.parse(text)) {
      return JSON.parse(text);
    } else {
      return text;
    }
  } catch (error) {}
};
