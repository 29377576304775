import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { MethodsService } from 'shared/services/methods.service';
import { TTransaction } from 'shared/services/types/transaction.types';

export const useGetTransactions = (data: TTransaction.GetTransaction) => {
  const {
    isLoading: isTransactionsLoading,
    data: transactions,
    isError: isTransactionsError,
    isFetching: isTransactionsFetching,
  } = useQuery({
    queryFn: () => MethodsService.getTransactions(data),
    queryKey: ['get transactions', data],
    retry: 0,
    select: ({ data }) => data,
    refetchInterval: 10000,
    placeholderData: keepPreviousData,
  });

  return { isTransactionsLoading, transactions, isTransactionsError, isTransactionsFetching };
};
