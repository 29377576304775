import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'shared/hooks';
import { Layout } from 'shared/ui/layout';

interface IProps extends PropsWithChildren {}

///@ts-ignore
export const ProtectedRoute: FC<IProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('access'));
  const [refresh, setRefreshToken] = useState(localStorage.getItem('refresh'));
  const { otp_verified: isOtpVerified } = useAuthStore();

  useEffect(() => {
    if (!token && !refresh) {
      navigate('/login');
    } else if (token && !isOtpVerified) {
      navigate('/two-factor');
    } else if (token && isOtpVerified && ['/login', '/two-factor'].includes(location.pathname)) {
      navigate('/');
    }
  }, [token, location.pathname, isOtpVerified]);

  const watchStorage = () => {
    setToken(localStorage.getItem('access'));
    setRefreshToken(localStorage.getItem('refresh'));
  };

  useEffect(() => {
    window.addEventListener('storage', watchStorage);
    return () => {
      window.removeEventListener('storage', watchStorage);
    };
  }, []);

  if (children) return <>{children}</>;

  return (
    <>
      <Layout>Error</Layout>
    </>
  );
};
