import { useLayoutEffect } from 'react';
import { Layout } from 'shared/ui/layout';
import { useUser } from 'shared/hooks';
import { useNavigate } from 'react-router-dom';
import { User } from 'widgets/users';

const UsersPage = () => {
  const { isAdmin } = useUser();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!isAdmin) {
      navigate('/');
    }
  }, [isAdmin]);

  return (
    <>
      <Layout bankName='Users' isSingleMethod hiddenHeader>
        <User />
      </Layout>
    </>
  );
};

export default UsersPage;
