import { GridColDef } from '@mui/x-data-grid';
import { ROWS } from './MOCK_ROW';

export const COLUMNS: GridColDef<(typeof ROWS)[number]>[] = [
  {
    field: 'status',
    headerName: 'Status',
  },
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'date',
    headerName: 'Date',
  },
  {
    field: 'transaction',
    headerName: 'Transaction',
  },
  {
    field: 'account',
    headerName: 'Account',
  },
  {
    field: 'subagent',
    headerName: 'Subagent',
  },
];
