import { useEffect, useRef, useState } from 'react';

const useOutsideClick = (
  isConfirmationNeeded: boolean,
  initialValue: boolean,
  callback?: React.Dispatch<React.SetStateAction<boolean>>,
  classNameSelector?: string
) => {
  const [isActive, setIsActive] = useState(initialValue);
  const ref = useRef<HTMLDivElement>(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClick = (e: globalThis.MouseEvent) => {
    const target = e.target as Node;

    if (ref.current && !ref.current.contains(target)) {
      //@ts-ignore
      const classList = Array.from(target.classList);

      if (classNameSelector && classList.includes(classNameSelector) && !classList.includes('MuiBackdrop-invisible')) {
        isConfirmationNeeded ? setShowConfirm(true) : callback?.(false);
      } else if (!classNameSelector) {
        callback?.(false);
      }
    } else {
      setIsActive(true);
    }
  };
  const handleConfirm = (confirmed: boolean) => {
    if (confirmed) {
      callback?.(false);
    }
    setShowConfirm(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return { ref, isActive, setIsActive, showConfirm, setShowConfirm, handleConfirm };
};
export default useOutsideClick;
