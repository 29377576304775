import { Button } from '@mui/material';
import { FC } from 'react';
import { CloseIcon } from 'shared/assets/icons';

interface IProps {
  onClick?: () => void;
  rightPercent?: number;
}

export const ClearFormItem: FC<IProps> = ({ onClick, rightPercent }) => {
  return (
    <Button
      sx={{
        position: 'absolute',
        zIndex: '2',
        top: '50%',
        right: `${rightPercent}%`,
        transform: 'translate(-50%, -50%)',
        width: 24,
        height: 24,
        cursor: 'pointer',
        minWidth: 24,
        p: 0,
        color: '#44464F',
        '&:hover': {
          color: '#185AC2',
        },
      }}
      variant='text'
      type='button'
      onClick={onClick}
    >
      <CloseIcon />
    </Button>
  );
};
