import { Bounce, toast } from 'react-toastify';
import { getMessageError } from './getMessageError';

export const addMessageToast = (text: any, type: 'success' | 'error' | 'info' = 'error') => {
  toast[type](getMessageError(text), {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    transition: Bounce,
  });
};
