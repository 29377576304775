import { TUsers } from 'shared/services/types/users.types';

const accountsAvailableOptions = (users: TUsers.GetAccountSuccess | undefined) => {
  const options = users?.items.map((account) => ({
    label: account.account_id,
    value: account.account_id,
  }));

  return options as { label: string | number; value: string | number }[];
};

const getAccountCurrency = (accountID: string, accounts: TUsers.GetAccountSuccess | undefined) => {
  const account = accounts?.items.find((account) => account.account_id === accountID);

  return account?.currency || '';
};

const isAccountActive = (accountID: string, accounts: TUsers.GetAccountSuccess | undefined) => {
  const account = accounts?.items.find((account) => account.account_id === accountID);

  return account?.is_active;
};

const accountBalance = (accountID: string, accounts: TUsers.GetAccountSuccess | undefined) => {
  const account = accounts?.items.find((account) => account.account_id === accountID);
  return account?.balance || null;
};

export { accountsAvailableOptions, getAccountCurrency, isAccountActive, accountBalance };
