import { useMutation } from '@tanstack/react-query';
import { TwoFactorAuthService } from 'shared/services/two-factor-auth/twoFactorAuth.service';
import { TAuth } from 'shared/services/types/auth.types';

export const useCreateTwoFactor = () => {
  const checkCode = useMutation({
    mutationFn: (data: TAuth.TwoFactorCheck) => TwoFactorAuthService.checkCode(data),
    mutationKey: ['create two factor'],
  });

  return { checkCode };
};
