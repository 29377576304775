import { Method } from 'widgets/method';

const MethodPage = () => {
  return (
    <>
      <Method />
    </>
  );
};

export default MethodPage;
