import { useState } from 'react';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
  const [openMethods, setOpenMethods] = useState(false);

  return (
    <>
      <Outlet context={[openMethods, setOpenMethods]} />;
    </>
  );
};

export default AppLayout;
