import { FieldValues } from 'react-hook-form';

import { format } from 'date-fns';

export const hasFormValuesChanged = (formFields: FieldValues, savedData?: FieldValues) => {
  const filledFields = Object.keys(formFields).filter((field) => ![undefined].includes(formFields[field]));
  if (!savedData) return false;

  const compareValues = (currentValue: any, savedValue: any): boolean => {
    // Если это дата, то форматируем её перед сравнением
    if (currentValue instanceof Date && savedValue instanceof Date) {
      const formattedCurrentDate = format(currentValue, 'dd/MM/yyyy');
      const formattedSavedDate = format(savedValue, 'dd/MM/yyyy');
      return formattedCurrentDate !== formattedSavedDate;
    }

    // Если это массив, то рекурсивно проверяем каждый элемент
    if (Array.isArray(currentValue) && Array.isArray(savedValue)) {
      if (currentValue.length !== savedValue.length) return true;
      for (let i = 0; i < currentValue.length; i++) {
        if (compareValues(currentValue[i], savedValue[i])) {
          return true;
        }
      }
      return false;
    }
    // Если это объект, сравниваем его структуру через JSON.stringify
    if (typeof currentValue === 'object' && typeof savedValue === 'object') {
      return JSON.stringify(currentValue) !== JSON.stringify(savedValue);
    }
    // Для примитивов используем обычное сравнение
    return currentValue !== savedValue;
  };

  for (let i = 0; i < filledFields.length; i++) {
    const fieldName = filledFields[i];
    const currentValue = formFields[fieldName];
    const savedValue = savedData[fieldName];

    if (compareValues(currentValue, savedValue)) {
      return true;
    }
  }

  return false;
};
