import { TAuth } from 'shared/services/types/auth.types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const defaultStore = {
  otp_verified: false,
  user: {},
} as TAuth.LoginSuccess;

interface StoreState extends TAuth.LoginSuccess {
  toogleOtp: (isSelected: boolean) => void;
  updateUser: (data: TAuth.LoginSuccess) => void;
  logout: () => void;
}

const useAuthStore = create<StoreState>()(
  persist(
    (set) => ({
      ...defaultStore,
      toogleOtp: (isOtpVerified: boolean) => set((state) => ({ otp_verified: isOtpVerified })),
      updateUser: (data: TAuth.LoginSuccess) => set((state) => ({ user: data.user })),
      logout: () => set((state) => ({ ...state, ...defaultStore })),
    }),
    {
      name: 'auth-storage',
    }
  )
);

export default useAuthStore;
