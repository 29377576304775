import { FormControl, FormLabel } from '@mui/material';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { Noop } from 'react-hook-form';
import { useOutsideClick } from 'shared/hooks';
import { DateRange } from 'shared/services/types/common.types';
import { usePositionCalendar } from './helper';
import { CustomHeader, DatePickerSlice, DateRangeSlice } from './slices';
import { styles } from './styles';

interface IProps {
  mono?: boolean;
  value: DateRange;
  setValue: React.Dispatch<React.SetStateAction<DateRange>>;
  labelText?: string;
  onBlur?: Noop;
}

export const FilterItemDate: FC<IProps> = ({ mono, value, setValue, labelText, onBlur }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { ref } = useOutsideClick(false, isOpen, setIsOpen);
  const { calendarRect } = usePositionCalendar(isOpen);
  const [isFull, setIsFull] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    //@ts-ignore
    value !== null && value[0] !== null ? setIsFull(true) : setIsFull(false);
  }, [value]);

  return (
    <FormControl
      className={clsx(isOpen && 'is-Active', isFull && 'is-Full', isDropdownOpen && 'is-Dropdown-Open')}
      ref={ref}
      sx={styles}
    >
      <FormLabel
        sx={{
          zIndex: '10',
          position: 'absolute',
          top: '-11px',
          left: '10px',
          padding: '2px 4px',
          background: '#fff',
          fontSize: '12px',
          lineHeight: '12px',
          color: '#44464F',
          fontFamily: 'Roboto',
          fontWeight: '400',
        }}
      >
        {labelText ?? 'Choose Date' + (mono ? '' : 's')}
      </FormLabel>
      {!mono ? (
        <DateRangeSlice
          value={value}
          onChange={setValue}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          onBlur={onBlur}
        />
      ) : (
        <DatePickerSlice
          value={value}
          onChange={setValue}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          onBlur={onBlur}
        />
      )}
      {isOpen && calendarRect && <CustomHeader value={value} mono={mono} calendarRect={calendarRect} />}
    </FormControl>
  );
};
