import { Box, Button, Checkbox, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IModalProps, ModalTemplate } from 'shared/ui/modal-template';
import { IStepProps } from '..';

interface IProps extends IModalProps, IStepProps {
  setText: (texts: string[]) => void;
}

const TEXT_TEST = [
  '+ 1 0443.03 ₽. Теперь на карте 562 127,04 ₽',
  'Перевод по СБП, SMS/Push, 2 вар.. Карта 3745. Сумма 221  30,02 RUB. Доступно 1003,03 RUB',
  `Cash Out Tk 5,000.00 to 0191XXXX871 successful. Fee Tk 0.00. Balance Tk 139,856.11. TrxID BIR3WQ3YZN at 27/09/2024 15:44`,
  `VİSA6849 14:33 зачисление 10 133р от Tinkoff Bank. Баланс: 16 832.06р`,
  `تم استلام مبلغ 50.0 جنيه من رقم 01099997212؛ المسجل بإسم Ahmed M Elsaid. رصيدك الحالي 17329.49 جنيه. تاريخ العملية \u200e30-10-24 11:40\u200e رقم العملية 008775520797. تابع كل مصروفاتك من تاريخ المعاملات على أبلكيشن أنا فودافون http://vf.eg/vfcash`,
];

export const SelectedTextModal: FC<IProps> = ({ close, isOpen, onBack, onNext, setText }) => {
  const [activeIndex, setActiveIndex] = useState<number[]>([]);
  const [textList, setTextList] = useState<string[]>(TEXT_TEST);
  const [textAreaOpen, setTextAreaOpen] = useState(false);
  const [textArea, setTextArea] = useState('');

  const onSelectedIndex = (id: number) => {
    const newIndex = [...activeIndex];
    if (newIndex.includes(id)) {
      newIndex.splice(newIndex.indexOf(id), 1);
    } else {
      newIndex.push(id);
    }
    setActiveIndex(newIndex);
  };

  const onNextStep = () => {
    setText(textList.filter((item, index) => activeIndex.includes(index)));
    onNext?.();
  };

  const onAddText = (data: any) => {
    setTextList((prevState) => {
      let ratata = [];
      ratata.push(...prevState, data);
      return ratata;
    });
    setTextAreaOpen(false);
  };

  return (
    <ModalTemplate close={close} isOpen={isOpen} titleText={'SMS/PUSH'} hideBackdrop={false} onBack={onBack}>
      <Box p={'34px 24px'} m={'0 auto'}>
        <Box display={'flex'} sx={{ flexDirection: 'column' }} gap={'16px'} maxHeight={'500px'} overflow={'auto'}>
          {textAreaOpen && (
            <TextField
              multiline
              placeholder='Enter SMS/PUSH textY'
              onChange={(data) => {
                setTextArea(data.target.value);
              }}
            ></TextField>
          )}
          <Button
            type='button'
            onClick={() => {
              textAreaOpen ? onAddText(textArea) : setTextAreaOpen(true);
            }}
          >
            Add text
          </Button>
          {textList.map((item, i) => (
            <Box
              key={item}
              onClick={() => onSelectedIndex(+i)}
              sx={{ cursor: 'pointer' }}
              display={'flex'}
              gap={'14px'}
              p={'12px 16px'}
              alignItems={'flex-start'}
              borderRadius={'4px'}
              border={'1px solid #BDC1D2'}
            >
              <Checkbox sx={{ padding: '0' }} checked={activeIndex.includes(i)} />

              <Box sx={{ textAlign: 'left', direction: 'ltr', unicodeBidi: 'bidi-override' }}>{item}</Box>
            </Box>
          ))}
        </Box>
        <Box display={'flex'} gap='10px'>
          <Button
            fullWidth
            variant='outlined'
            onClick={onBack}
            sx={{ borderRadius: 50, textTransform: 'none', height: 40, marginTop: 15, marginBottom: 3 }}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            disabled={!activeIndex.length}
            variant='contained'
            onClick={onNextStep}
            sx={{ borderRadius: 50, textTransform: 'none', height: 40, marginTop: 15, marginBottom: 3 }}
          >
            Choose
          </Button>
        </Box>
      </Box>
    </ModalTemplate>
  );
};
