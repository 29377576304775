import { createContext, useState } from 'react';

type FilterType = {
  dateFrom?: string;
  dateTo?: string;
  accountNumber?: string;
  status?: string;
};

type FilterState = {
  [key: string]: string | FilterType | null;
};

const FilterContext = createContext<any>(null);

const FilterProvider = ({ children }: any) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState<FilterState>({});

  const updateFilter = (method: string, allOperations: string | null) => (value: FilterType) => {
    const filteredValue = Object.fromEntries(
      Object.entries(value).filter(([key, value]) => value !== '' && value !== undefined)
    );

    setFilter({ ...filter, [method]: filteredValue, currentMethod: method, mode: allOperations });
  };

  return (
    <FilterContext.Provider value={{ filter, updateFilter, isFilterOpen, setIsFilterOpen }}>
      {children}
    </FilterContext.Provider>
  );
};

export { FilterContext, FilterProvider };
